<template>
  <component :is="user.isFoodie ? 'edit-foodie-profile' : 'edit-restaurant-profile'" />
</template>

<script>
import { mapState } from 'vuex'
import EditFoodieProfile from './foodies/profile/edit'
import EditRestaurantProfile from './restaurants/profile/edit'

export default {
  components: {
    EditFoodieProfile,
    EditRestaurantProfile
  },
  computed: mapState({
    user: ({ session }) => session.user
  })
}
</script>
