<template>
  <a-dims-search-bar hide-search>
      <template #search-button>
        <button @click="update" class="focus:outline-none">
          <transition name="fade" mode="out-in">
            <a-loader class="h-6 w-6" color="text-blue-500" v-if="loading" />
            <i ref="checkIcon" v-else class="icon-checkmark text-primary font-bold text-xl p-4 inline-block -mx-4 select-none" />
          </transition>
        </button>
      </template>

      <div class="flex uppercase justify-between p-4 space-x-2 -mx-4">
        <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
          <i class="icon-arrowLeft text-gray-600" />
          <span class="font-bold">Editar perfil</span>
        </button>
      </div>
    </a-dims-search-bar>
<!--  <a-loader v-if="loading" class="mt-14 w-6 h-6"></a-loader>-->
  <div class="flex flex-col h-full mt-14">
    <div class="flex-auto">
<!--      <input type="file" @change="onFileChange" class="hidden" ref="coverInput" id="coverInput"/>-->
<!--      <div @click="inputSelect('cover')" style="background: #3D3D3D;height: 156px;margin-top: 56px;padding-top: 28px;">-->
<!--        &lt;!&ndash;    <img v-if="user.profile_picture" style="margin: 0 auto;width: 80px; height: 80px; margin-bottom:10px" class="avatar_img" :src="user.profile_picture" alt="foodie"/>&ndash;&gt;-->
<!--        &lt;!&ndash;    <img v-else style="margin: 0 auto;width: 80px; height: 80px; margin-bottom:10px" class="avatar_img" src="../../../../assets/logo.png" alt="foodie"/>&ndash;&gt;-->
<!--        <input type="file" @change="onFileChange" class="hidden" ref="imageInput" id="imageInput"/>-->
<!--        <a-initials @click="inputSelect('profile')" :src="previewImage" :name="user.name" :desing-class="'h-20 w-20 block mx-auto'"/>-->
<!--        <b class="text-white text-lg pt-2">Cambiar foto de perfil</b>-->
<!--      </div>-->
      <div class="block items-start">
        <div class="absolute custom-picture-position mt-normal">
          <input type="file" @change="onFileChange" class="hidden" ref="imageInput" id="imageInput" accept="image/png, image/jpeg" />
          <a-initials @click="inputSelect('profile')" :src="previewImage" :name="user.name" :desing-class="'h-20 w-20 block mx-auto'"/>
          <b class="text-white text-lg pt-2">Cambiar foto de perfil</b>
        </div>
        <input type="file" @change="onCoverChange" class="hidden" ref="coverInput" id="coverInput" accept="image/png, image/jpeg" />
        <div @click="inputSelect('cover')" class="h-32 bg-dgray bg-no-repeat"
             :style="`background-repeat: no-repeat !important;background-position: center !important;background-size: cover!important; ${previewCover ? 'background: url(' + previewCover + ');' : 'background-color: #3D3D3D'}`">
          <div class="text-lg absolute mt-2 ml-2 text-center text-dgray bg-white rounded-sm h-6 w-6 cursor-pointer">
            <i class="icon-copy font-bold" />
          </div>
        </div>
      </div>

      <div class="block items-start px-4 pt-4">
        <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Nombre</label>
        <input type="text" v-model="user.name" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="name">
      </div>

      <div class="block items-start px-4 pt-4">
        <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Nombre de usuario</label>
        <input type="text" :value="user.ig_username ? `@${user.ig_username}` : ''" disabled class="align-middle border-none underlined py-2 text-lg inline-block disabled:text-gray" style="background: white;width: calc(100% - 120px);" id="ig_name">
      </div>

      <div class="block items-start px-4 pt-4">
        <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Biografía</label>
        <input type="text" v-model="user.biography" class="align-middle border-none underlined py-2 text-lg inline-block" style="background: white;width: calc(100% - 120px);" id="biography">
      </div>

      <div class="block items-start px-4 pt-4 text-left">
        <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Teléfono</label>
        <div class="inline-block text-left">
          <p class="inline-block mr-1 text-gray text-lg">+34</p>
          <!--      <input type="text" :value="`${user.phone}`"  class="align-middle border-none underlined py-2 inline-block" style="background: white;width: calc(100% - 120px);" id="phone">-->
          <Mask
            id="phone"
            v-model="auxPhone"
            class="inline-block text-lg"
            placeholder="Teléfono de reservas"
            mask="999999999"/>
        </div>
      </div>

      <div class="block items-start px-4 pt-6 text-left">
        <p class="font-bold text-left text-lg inline-block align-middle" style="width: 120px">Ocultar perfil</p>
        <InputSwitch v-model="user.private_profile" class="inline-block"/>
      </div>

      <div v-if="getEnv" class="block items-start px-4 pt-6 text-left">
        <p class="font-bold text-left text-lg inline-block align-middle" style="width: 120px">Handshake</p>
        <InputSwitch @change="setSettings" v-model="public_collabs_handshake_enabled" class="inline-block"/>
      </div>
    </div>

    <div class="flex-auto mt-8">
      <h2 class="text-lg font-bold text-primary">Dirección</h2>
      <div class="block items-start px-4 pt-4">
        <label for="address" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Dirección</label>
        <input id="address" type="text" v-model="user.address" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="city" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Ciudad</label>
        <input id="city" type="text" v-model="user.city" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4 text-left">
        <label class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Est./Provincia</label>
<!--        <input disabled type="text" v-model="user.state" class="border-none underlined py-2 text-lg inline-block align-middle disabled:text-gray" style="width: calc(100% - 120px);">-->
        <select id="companionSelectorSS" class="text-left flex-1 border p-1 rounded px-2" v-model="user.state">
          <option value="madrid">Madrid</option>
          <option value="barcelona">Barcelona</option>
          <option value="sevilla">Sevilla</option>
          <option value="bilbao">Bilbao</option>
          <option value="valencia">Valencia</option>
          <option value="málaga">Málaga</option>
        </select>
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="zipcode" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Código postal</label>
        <input id="zipcode" type="text" v-model="user.zip_code" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
    </div>

    <div v-if="user?.billing_address" class="flex-auto mt-8">
      <h2 class="text-lg font-bold text-primary">Datos de facturación</h2>
      <div class="block items-start px-4 pt-4">
        <label for="bbname" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Razón social</label>
        <input id="bbname" type="text" v-model="user.billing_address.business_name" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="bcif" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">CIF</label>
        <input
          id="bcif"
          type="text"
          v-model="inputCif"
          :class="{'border-b-2 border-red-400': errors.cif}"
          @input="validateCif($event)"
          class="underlined py-2 text-lg inline-block align-middle uppercase"
          style="width: calc(100% - 120px);">
        <p class="small text-red-400" v-if="errors.cif">El CIF introducido es incorrecto.</p>
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="baddress" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Dirección</label>
        <input id="baddress" type="text" v-model="user.billing_address.address_line" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="bcity" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Ciudad</label>
        <input id="bcity" type="text" v-model="user.billing_address.city" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4">
        <label class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Est./Provincia</label>
        <input disabled type="text" v-model="user.billing_address.state" class="border-none underlined py-2 text-lg inline-block align-middle disabled:text-gray" style="width: calc(100% - 120px);">
      </div>
      <div class="block items-start px-4 pt-4">
        <label for="bzipcode" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Código postal</label>
        <input id="bzipcode" type="text" v-model="user.billing_address.zip_code" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);">
      </div>
    </div>

    <div class="flex-none flex content-between items-center px-4" style="bottom: 55px;">
      <div class="flex-auto text-left">
        <router-link :to="{ name: 'profile.user.security', params: { id: $route.params.id } }"
                     class="font-bold py-4 text-left inline-block font-medium text-primary select-none">
          Opciones de seguridad
        </router-link>
      </div>
      <div class="flex-none">
        <button
          @click="update" :disabled="auxPhone < 9 || !auxPhone || !user.cif || user.cif.length < 9"
          class="flex-none bg-primary rounded-3xl text-white py-2 px-4 disabled:bg-gray disabled:text-white">Guardar</button>
      </div>
<!--      <router-link :to="{ name: 'profile.user.security', params: { id: $route.params.id } }"-->

<!--      <div class="text-right inline-block" style="width: 120px;">-->
<!--      </div>-->
    </div>
    <button @click="$router.push({ path: `/subscription/${current.id}/change-plan` })" class="mb-20 mt-4 text-primary block mx-auto text-lg font-bold">Cambiar plan</button>
  </div>

  <a-alert :show="show.success" hide-cancel @confirm="$router.go()">
    <div class="flex space-x-4 items-start">
      <i class="icon-checkmark p-4 text-xl bg-green-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Guardado!</h1>
        <h1 class="text-xl mt-2">Tus datos han sido actualizados correctamente</h1>
      </div>
    </div>
  </a-alert>

  <a-alert :show="show.failure" hide-cancel @confirm="show.failure = false">
    <div class="flex space-x-4 items-start">
      <i class="icon-close p-4 text-xl bg-red-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Ha ocurrido un error!</h1>
        <h1 class="text-xl mt-2">Por favor revise los datos</h1>
      </div>
    </div>
  </a-alert>

  <a-alert hide-cancel :show="imageUploadFail" @confirm="imageUploadFail = false">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-red">Tamaño máximo excedido</h1>
      <p>No se pudo subir la imagen seleccionada ya que tiene un peso mayor a <b>4MB</b>, por favor inténtelo nuevamente.</p>
    </div>
  </a-alert>

</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Mask from 'primevue/inputmask'
import InputSwitch from 'primevue/inputswitch'

export default {
  data: () => ({
    restaurant: [],
    user: {
      name: '',
      ig_username: '',
      phone: '',
      private_profile: false,
      profile_picture: '',
      biography: '',
      cover_picture: ''
    },
    profile_picture: '',
    auxPhone: '',
    previewImage: '',
    previewCover: '',
    profile_picture_url: '',
    settings: [],
    public_collabs_handshake_enabled: false,
    loading: false,
    loadingConnect: false,
    show: {
      success: false,
      failure: false
    },
    switch: false,
    FBError: false,
    FBCError: '',
    imageUploadFail: false,
    inputCif: '',
    errors: {}
  }),
  components: {
    InputSwitch,
    Mask
  },
  computed: mapState({
    current: ({ session }) => session.user,
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    getEnv () {
      return process.env.VUE_APP_NODE_ENV === 'dev'
    }
  }),
  methods: {
    ...mapMutations({
      setUser: 'session/SET_USER'
    }),
    ...mapActions({
      facebookConnect: 'session/facebookConnect'
    }),
    validateCif (event) {
      const oldValue = this.user.cif
      const newValue = event.target.value
      // eslint-disable-next-line no-unused-vars
      // const regex = new RegExp(/^[a-zA-Z]{1}?[0-9]{8}/)
      const regexLetter = new RegExp(/^[a-zA-Z]{1}/)
      // const regexNumber = new RegExp('/\d\b/')
      // const test = regex.test(newValue)
      console.log(newValue.length)
      switch (newValue.length) {
        case 0: {
          this.inputCif = ''
          this.user.cif = ''
          this.user.billing_address.cif = ''
          break
        }
        case 1: {
          if (regexLetter.test(newValue)) {
            this.inputCif = newValue
            this.user.cif = newValue
            this.user.billing_address.cif = newValue
          } else {
            this.user.cif = ''
            this.oldValue = ''
            this.inputCif = ''
          }
          break
        }
        default: {
          // console.log('default')
          // console.log(regexNumber.test(newValue))
          if (newValue.length < 9) {
            if (/[0-9]$/.test(newValue)) {
              this.user.cif = newValue
              this.user.billing_address.cif = newValue
              this.inputCif = newValue
              // oldValue = newValue
            } else {
              this.user.cif = oldValue
              this.user.billing_address.cif = oldValue
              this.inputCif = oldValue
            }
          } else if (newValue.length === 9) {
            if (/[a-zA-Z0-9]$/.test(newValue)) {
              this.inputCif = newValue
              this.user.cif = newValue
              this.user.billing_address.cif = newValue
            } else {
              this.user.cif = oldValue
              this.user.billing_address.cif = oldValue
              this.inputCif = oldValue
            }
          } else {
            this.inputCif = oldValue
            this.user.cif = oldValue
            this.user.billing_address.cif = oldValue
          }
          break
        }
      }
    },
    inputSelect (type) {
      if (type === 'profile') {
        this.$refs.imageInput.click()
      } else {
        this.$refs.coverInput.click()
      }
    },
    onFileChange (e) {
      if (e) {
        const file = e.target.files[0]
        if (file.size > 4000000) {
          this.imageUploadFail = true
        } else {
          const formData = new FormData()

          this.previewImage = URL.createObjectURL(file)

          formData.append('picture', file)

          // console.log(this.profile_picture_url)
          const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
          axios
            .post(`users/${this.current.id}/upload-profile-picture`, formData, config)
            .catch((err) => (console.log(err)))
        }
      }
    },
    onCoverChange (e) {
      if (e) {
        const file = e.target.files[0]
        if (file.size > 4000000) {
          this.imageUploadFail = true
        } else {
          const formData = new FormData()

          this.previewCover = URL.createObjectURL(file)

          formData.append('picture', file)

          // console.log(this.profile_picture_url)
          const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
          axios
            .post(`restaurants/${this.current.restaurant.id}/upload-cover-picture`, formData, config)
            .catch((err) => (console.log(err)))
        }
      }
    },
    update () {
      this.loading = !this.loading
      this.user.phone = '+34' + this.auxPhone
      delete this.user.latitude
      delete this.user.longitude

      this.$repository.restaurants
        .update(this.$route.params.id, this.user)
        .then(() => {
          this.show.success = true
        })
        .catch((err) => {
          this.errors = err.response.data.errors
          // console.log(this.errors.includes('cif'))
          this.show.failure = true
        })
        .finally(() => {
          this.loading = !this.loading
        })
    },
    fetchSettings () {
      this.$repository.restaurants
        .getSettings(this.current.restaurant.id)
        .then((response) => {
          this.public_collabs_handshake_enabled = response.data.public_collabs_handshake_enabled
        })
    },
    setSettings () {
      this.$repository.restaurants
        .setSettings(this.current.restaurant.id, { public_collabs_handshake_enabled: !this.public_collabs_handshake_enabled })
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.loading = !this.loading
      this.fetchSettings()
      this.$repository.restaurants
        .show(this.$route.params.id, { with: 'billingAddress' })
        .then((response) => {
          this.values = response?.data
          if (this.values.phone) {
            this.auxPhone = this.values.phone.slice(3)
          } else {
            this.auxPhone = ''
          }
          this.user = { ...this.values, phone: this.auxPhone, state: this.values.state.toLowerCase() }
          this.inputCif = this.user.billing_address.cif
          this.loading = !this.loading
          // this.user = {
          //   name: this.values.name,
          //   phone: this.auxPhone,
          //   ig_username: this.values.ig_username,
          //   profile_picture: this.values.profile_picture,
          //   biography: this.values.biography,
          //   cover_picture: this.values.cover_picture,
          //   private_profile: this.values.private_profile
          // }
          this.previewImage = this.values.profile_picture
          this.previewCover = this.values.cover_picture
        })
        .catch((error) => (console.log(error)))
    }
    // console.log(this.current)
  }
}
</script>
<style scoped>
.p-inputmask{
  background-color: white !important;
  border: none;
  text-align: left !important;
  font-size: 16px !important;
  padding: 0 !important;
}
.p-inputmask:enabled:focus {
  /*border: none !important;*/
  box-shadow: 0 0 0 0 #23BB2D !important;
  border: none;
  border-radius: 0;
}
.p-inputtext{
  border-radius: 0 !important;
}
</style>
