<template>
  <a-dims-search-bar hide-search>
    <template #search-button>
      <button @click="update" class="focus:outline-none">
        <transition name="fade" mode="out-in">
          <a-loader class="h-6 w-6" color="text-blue-500" v-if="loading" />
          <i ref="checkIcon" v-else class="icon-checkmark text-primary font-bold text-xl p-4 inline-block -mx-4 select-none" />
        </transition>
      </button>
    </template>

    <div class="flex uppercase justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Editar perfil</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div style="background: #3D3D3D;height: 156px;margin-top: 56px;padding-top: 28px;">
<!--    <img v-if="user.profile_picture" style="margin: 0 auto;width: 80px; height: 80px; margin-bottom:10px" class="avatar_img" :src="user.profile_picture" alt="foodie"/>-->
<!--    <img v-else style="margin: 0 auto;width: 80px; height: 80px; margin-bottom:10px" class="avatar_img" src="../../../../assets/logo.png" alt="foodie"/>-->
    <input type="file" @change="onFileChange" class="hidden" ref="imageInput" id="imageInput" accept="image/png, image/jpeg" />
    <a-initials @click="inputSelect" :src="previewImage" :name="user.name" :desing-class="'h-20 w-20 block mx-auto'"/>
    <b class="text-white text-lg">Cambiar foto de perfil</b>
  </div>

  <div class="block items-start px-4 pt-6">
    <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Nombre</label>
    <input type="text" v-model="user.name" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="name">
  </div>

  <div class="block items-start px-4 pt-6">
    <label for="name" class="font-bold text-left text-lg text-gray inline-block align-middle" style="width: 120px;">Nombre de usuario</label>
    <input type="text" :value="`${user.ig_username ? '@' + user.ig_username : ''}`" disabled class="align-middle text-gray border-none underlined py-2 text-lg inline-block" style="background: white;width: calc(100% - 120px);" id="ig_name">
  </div>

  <div class="block items-start px-4 pt-4">
    <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Biografía</label>
    <input type="text" v-model="user.biography" class="align-middle border-none underlined py-2 text-lg inline-block" style="background: white;width: calc(100% - 120px);" id="biography">
  </div>

<!--  <div class="block items-start px-4 pt-6">-->
<!--    <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Biografía</label>-->
<!--    <input type="text" :value="`${user.biography}`"  class="align-middle text-gray border-none underlined py-2 text-lg inline-block" style="background: white;width: calc(100% - 120px);" id="bigraphy">-->
<!--  </div>-->

  <div class="block items-start px-4 pt-6 text-left">
    <label for="name" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Teléfono</label>
    <div class="inline-block text-left">
      <p class="inline-block mr-1 text-gray text-lg">+34</p>
<!--      <input type="text" :value="`${user.phone}`"  class="align-middle border-none underlined py-2 inline-block" style="background: white;width: calc(100% - 120px);" id="phone">-->
      <Mask
        id="phone"
        v-model="auxPhone"
        class="inline-block text-lg"
        placeholder="Teléfono para reserva *"
        mask="999999999"/>
    </div>
  </div>

  <!--div class="border-b flex items-start">
    <label for="phone" class="text-lg inline-block text-gray-800" style="width: 120px;">Teléfono</label>
    <input v-model="user.phone" type="text" class="inline-block border-none underlined py-2 text-lg w-full" style="width: calc(100% - 120px);" id="phone">
  </div-->

  <div class="flex px-4 mt-4 font-medium text-primary select-none font-bold">
    <router-link :to="{ name: 'profile.user.direction' }">Editar dirección</router-link>
  </div>

  <div class="flex items-center px-4 content-between" style="bottom: 55px;">
    <router-link :to="{ name: 'profile.user.security', params: { id: current.foodie.id } }"
      class="font-bold py-4 text-left inline-block font-medium text-primary select-none flex-1">
      Opciones de seguridad
    </router-link>
    <div class="text-right inline-block" style="width: 120px;">
      <button @click="update" class="bg-primary rounded-3xl text-white py-2 px-4 flex-1">Guardar</button>
    </div>
  </div>

  <a-alert :show="show.success" hide-cancel @confirm="$router.go()">
    <div class="flex space-x-4 items-start">
      <i class="icon-checkmark p-4 text-xl bg-green-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Guardado!</h1>
        <h1 class="text-xl mt-2">Tus datos han sido actualizados correctamente</h1>
      </div>
    </div>
  </a-alert>
  <a-alert hide-cancel :show="imageUploadFail" @confirm="imageUploadFail = false">
    <div class="text-left space-y-4">
      <h1 class="text-lg font-semibold text-red">Tamaño máximo excedido</h1>
      <p>No se pudo subir la imagen seleccionada ya que tiene un peso mayor a <b>4MB</b>, por favor inténtelo nuevamente.</p>
    </div>
  </a-alert>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Mask from 'primevue/inputmask'
import axios from 'axios'

export default {
  data: () => ({
    values: [],
    user: {
      name: '',
      phone: '',
      ig_username: '',
      profile_picture: '',
      biography: ''
    },
    previewImage: '',
    auxPhone: '',
    loading: false,
    show: {
      success: false,
      failure: false
    },
    imageUploadFail: false
  }),
  components: {
    Mask
  },
  computed: mapState({
    current: ({ session }) => session.user,
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    })
  }),
  methods: {
    ...mapMutations({
      setUser: 'session/SET_USER'
    }),
    inputSelect () {
      this.$refs.imageInput.click()
    },
    onFileChange (e) {
      if (e) {
        const file = e.target.files[0]
        if (file.size > 4000000) {
          this.imageUploadFail = true
        } else {
          const formData = new FormData()

          this.previewImage = URL.createObjectURL(file)

          formData.append('picture', file)

          // console.log(this.profile_picture_url)
          const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
          axios
            .post(`users/${this.current.id}/upload-profile-picture`, formData, config)
            .catch((err) => (console.log(err)))
        }
      }
    },
    update () {
      this.loading = !this.loading
      this.user.phone = '+34' + this.auxPhone
      this.$repository.foodies
        .update(this.$route.params.id, this.user)
        .then(({ data: foodie }) => {
          this.setUser({ ...this.current, foodie })
          this.show.success = true
        })
        .finally(() => {
          this.loading = !this.loading
          // this.show.success = true
        })
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$repository.foodies
        .show(this.$route.params.id)
        .then((response) => {
          this.values = response?.data
          if (this.values.phone) {
            this.auxPhone = this.values.phone.slice(3)
          } else {
            this.auxPhone = ''
          }
          this.user = {
            name: this.values.name,
            phone: this.auxPhone,
            ig_username: this.values.ig_username,
            profile_picture: this.values.profile_picture,
            biography: this.values.biography,
            address_line: 'adiiiiiiiiiiiiiiiii'
          }
          this.previewImage = this.values.profile_picture
        })
        .catch((error) => (console.log(error)))
    }
    // }
    // console.log(this.current)
  }
}
</script>
<style scoped>
.p-inputmask{
  background-color: white !important;
  border: none;
  text-align: left !important;
  font-size: 16px !important;
  padding: 0 !important;
}
.p-inputmask:enabled:focus {
  /*border: none !important;*/
  box-shadow: 0 0 0 0 #23BB2D !important;
  border: none;
  border-radius: 0;
}
.p-inputtext{
  border-radius: 0 !important;
}
</style>
